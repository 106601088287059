import React, { useEffect } from "react";
import RequestBuy from "../components/common/requestForm/RequestBuy";
import RequestGetList from "../components/common/requestForm/RequestGetList";
import {
  FaMoneyBillAlt,
  FaRegClock,
  FaRegEdit,
  FaRegFileAlt,
  FaTimes,
} from "react-icons/fa";
import { useState } from "react";
import Loading from "../components/common/Loading";
import EditProductForm from "../components/common/forms/EditProductForm";
import EditTimerProduct from "../components/common/forms/EditTimerProduct";
import CreateProductForm from "../components/common/forms/CreateProductForm copy";
import TableBuySell from "../components/common/TableBuySell";
import TableListPrice from "../components/common/TableListPrice";
import CreateSubProductForm from "../components/common/forms/CreateSubProductForm";
import EditDealerData from "../components/common/forms/EditDealerData";
import EditSubProductForm from "../components/common/forms/EditSubProductForm";
import { httpReauest } from "../utils/httpRequest";
import { toast } from "react-toastify";

const ProductManagePage = () => {
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [timing, settiming] = useState();
  const [subedit, setsubedit] = useState();
  const [list, setlist] = useState();
  const [loading, setLoading] = useState();
  const [end_sale, setend_sale] = useState();

  async function getData() {
    const res2 = await httpReauest("GET", "/data/info", {}, {});
    setend_sale(res2?.data?.data?.end_sale);
  }

  useEffect(() => {
    getData();
  }, []);

  async function handleCreateShow(params) {
    setCreating(true);
  }

  async function handleEditShow(params) {
    setEditing(true);
  }
  async function handleTimeShow(params) {
    settiming(true);
  }

  async function handleEditSubShow(params) {
    setsubedit(true);
  }

  async function handleListShow(params) {
    setlist(true);
  }

  function handleBack() {
    setCreating(false);
    setEditing(false);
    settiming(false);
    setlist(false);
    setsubedit(false);
  }

  if (loading) {
    return <Loading />;
  }

  async function handleChange(event) {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "POST",
      "/data/end_sale",
      { end: event.target.checked },
      {
        "x-access-token": user.token,
      }
    ).then(() => {
      toast.success("وضعیت فروش تغییر یافت");
    });
  }
  return (
    <div className="w-full">
      <h1 className="title mb-10">مدیریت محصولات</h1>
      {!editing && !creating && !timing && !list && !subedit && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <span className="col-span-full flex justify-center">
            <span className="flex items-center gap-3">
              <span className="text-[#2E3132] text-[16px] font-[500]">
                قطع فروش محصولات
              </span>
              <label class="inline-flex items-center cursor-pointer">
                <input
                  id="chat"
                  name="chat"
                  type="checkbox"
                  value={end_sale}
                  checked={end_sale}
                  onChange={handleChange}
                  class="sr-only peer"
                />
                <div class="relative w-11 h-[25px] bg-white peer-focus:outline-none rounded-full peer border peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full border-black after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-[19px] after:w-[19px] after:transition-all dark:border-gray-600 after:peer-checked:bg-white peer-checked:bg-black"></div>
              </label>
            </span>
          </span>

          <span className="col-span-full">
            <p className="text-center font-bold text-[28px]">
              چه کاری میخوای انجام بدی؟
            </p>
          </span>
          <span
            onClick={handleCreateShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              ایجاد محصول جدید
            </p>
            <FaRegFileAlt
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleEditShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              تغییر محصولاتی موجود
            </p>
            <FaRegEdit
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleTimeShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">ایجاد زیر محصول</p>
            <FaRegEdit
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleEditSubShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">تغییر زیر محصول</p>
            <FaRegEdit
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleListShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">اطلاعات واریز</p>
            <FaRegEdit
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
        </div>
      )}

      {(editing || creating || timing || list || subedit) && (
        <>
          <button onClick={handleBack} className="btn-brand mb-10">
            بازگشت
          </button>
          <br />
        </>
      )}

      {creating && <CreateProductForm />}
      {editing && <EditProductForm />}
      {timing && <CreateSubProductForm />}
      {subedit && <EditSubProductForm />}
      {list && <EditDealerData />}
    </div>
  );
};

export default ProductManagePage;
