import React, { useEffect } from "react";
import RequestBuy from "../components/common/requestForm/RequestBuy";
import RequestGetList from "../components/common/requestForm/RequestGetList";
import { FaRegEdit, FaRegFileAlt } from "react-icons/fa";
import { useState } from "react";
import Loading from "../components/common/Loading";
import CreateProductForm from "../components/common/forms/EditProductForm";
import CreateBrandForm from "../components/common/forms/CreateBrandForm";
import EditBrandForm from "../components/common/forms/EditBrandForm";
import ReactQuill from "react-quill";
import CreateCategoryForm from "../components/common/forms/CreateCategoryForm";
import EditCategoryForm from "../components/common/forms/EditCategoryForm";
import UserCourseManage from "../components/common/forms/UserCourseManage";
import CreateCourseForm from "../components/common/forms/CreateCourseForm";
import EditCourseForm from "../components/common/forms/EditCourseForm";
import { httpReauest } from "../utils/httpRequest";
import { toast } from "react-toastify";

const CoursesManagePage = () => {
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [user, setuser] = useState();
  const [loading, setLoading] = useState();
  const [discounted, setdiscounted] = useState();

  async function getData() {
    const res2 = await httpReauest("GET", "/data/info", {}, {});
    setdiscounted(res2?.data?.data?.discounted);
  }

  useEffect(() => {
    getData();
  }, []);

  async function handleCreateShow(params) {
    setCreating(true);
  }
  async function handleEditShow(params) {
    setEditing(true);
  }
  async function handleUserShow(params) {
    setuser(true);
  }

  function handleBack() {
    setCreating(false);
    setEditing(false);
    setuser(false);
  }

  async function handleChange(event) {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "POST",
      "/data/discounted",
      { discounted: discounted },
      {
        "x-access-token": user.token,
      }
    ).then(() => {
      toast.success("کد تخفیف تغییر یافت");
    });
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="w-full">
      <h1 className="title mb-10">مدیریت دوره ها</h1>
      {!editing && !creating && !user && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <span className="col-span-full">
            <p className="text-center font-bold text-[28px]">
              چه کاری میخوای انجام بدی؟
            </p>
          </span>
          <span className="col-span-full flex justify-center">
            <span className="flex items-center gap-3">
              <span className="text-[#2E3132] text-[16px] font-[500]">
                کد تخفیف
              </span>
              <label class="inline-flex items-center cursor-pointer">
                <input
                  id="discounted"
                  value={discounted}
                  name="discounted"
                  onChange={(e) => setdiscounted(e.target.value)}
                  type="text"
                  class="form-brand h-[48px]"
                />
              </label>
              <button className="btn-brand" onClick={handleChange}>
                تایید
              </button>
            </span>
          </span>
          <span
            onClick={handleCreateShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">ایجاد دوره</p>
            <FaRegFileAlt
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleEditShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              تغییر دوره های موجود
            </p>
            <FaRegEdit
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleUserShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              کاربرهای ثبت نام شده
            </p>
            <FaRegFileAlt
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
        </div>
      )}

      {(editing || creating || user) && (
        <>
          <button onClick={handleBack} className="btn-brand mb-10">
            بازگشت
          </button>
          <br />
        </>
      )}

      {editing && <EditCourseForm />}
      {creating && <CreateCourseForm />}
      {user && <UserCourseManage />}
    </div>
  );
};

export default CoursesManagePage;
