import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import Loading from "../Loading";
import { httpReauest } from "../../../utils/httpRequest";
import UserCourseCard from "../card/UserCourseCard";

const UserCourseManage = () => {
  const [data, setdata] = useState();

  async function getData(params) {
    const res = await httpReauest("GET", "/course/user", {}, {});
    setdata(res?.data?.data);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">کاربرهای ثبت نامی</h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
        {data?.map((item) => (
          <UserCourseCard item={item} />
        ))}
      </div>
    </div>
  );
};

export default UserCourseManage;
