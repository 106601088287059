import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";

const EditProductForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [categorys, setCategorys] = useState();
  const [vehicles, setVehicles] = useState();
  const [selectedVehicle, setselectedVehicle] = useState();
  const [loading, setloading] = useState();

  function getFileName(filePath) {
    // جدا کردن مسیر با استفاده از '/' و '\' و سپس گرفتن آخرین بخش
    const parts = filePath.split(/[/\\]/);
    const fileNameWithExtension = parts.pop(); // آخرین بخش را می‌گیرد

    // حذف شماره‌ها و نگه‌داشتن فقط نام فایل و پسوند
    const fileName = fileNameWithExtension.replace(/-.*(?=\.)/, ""); // حذف همه چیز بعد از '-' و قبل از '.'

    return fileName;
  }

  const toDataURL = (url) => {
    return fetch(url)
      .then((response) => {
        // بررسی وضعیت پاسخ
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const file = new File([blob], getFileName(url), {
              type: blob.type || "application/octet-stream", // نوع MIME پیش‌فرض
            });
            resolve(file);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  };

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
    });
  };

  async function deleteImg(index) {
    setPreview((students) => students.filter((s, i) => i != index));
    setImages((students) => students.filter((s, i) => i != index));
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      desc: "",
      category: [],
      weight: "",
      balance: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("desc", values.desc);
      formdata.append("category", JSON.stringify(values.category));
      formdata.append("weight", values.weight);
      formdata.append("balance", values.balance);
      if (img) {
        formdata.append("img", img, img?.name);
      }

      if (images) {
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index], images[index]?.name);
        }
      }

      await fetch(BASE_URL + "/product/" + selectedVehicle?._id, {
        method: "PUT",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول تغییر یافت");
          setloading(false);
          formik.resetForm();
          setImg(null);
          setselectedVehicle(null);
          setImages([]);
          setPreviewImg(null);
          setPreview([]);
        } else {
          setloading(false);
          toast.error(dat);
        }
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  function handleImages(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils?.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImages(fils[index]);
        }
      }
    }
  }

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    setloading(true);

    await httpReauest("GET", "/category", {}, {}).then(({ data }) => {
      setCategorys(data?.data);
    });

    await httpReauest("GET", "/product/names/dash", {}, {}).then(({ data }) => {
      setVehicles(data?.data);
    });
    setloading(false);
  }

  async function handleChange(e) {
    setImages([]);
    setPreview([]);
    const { data } = await httpReauest(
      "GET",
      "/product/" + e.target.value,
      {},
      {}
    );

    const newVehicle = data?.data?.vehicle;

    setselectedVehicle(newVehicle);
    formik.setFieldValue("name", newVehicle.name);
    formik.setFieldValue("desc", newVehicle.desc);
    formik.setFieldValue("category", newVehicle.category);
    formik.setFieldValue("weight", newVehicle.weight);
    formik.setFieldValue("balance", newVehicle.balance);

    setPreviewImg(CDN_BASE_URL + newVehicle?.img);
    newVehicle?.images?.map((e) => {
      toDataURL(CDN_BASE_URL + e)
        .then((file) => {
          setImages((current) => [...current, file]);
        })
        .catch((e) => {
          console.log(e);
        });
      setPreview((current) => [...current, CDN_BASE_URL + e]);
    });
  }

  async function handleDelete(params) {
    const user = JSON.parse(localStorage.getItem("user"));
    await fetch(BASE_URL + "/product/" + selectedVehicle?._id, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول حذف شد");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  function handleDeleteCategory(_id) {
    const filtered = formik.values.category.filter((item) => item !== _id);
    formik.setFieldValue("category", filtered);
  }

  function handleAddCategory(_id) {
    if (formik.values.category.includes(_id) || _id == "" || !_id) {
      return;
    }
    const filtered = [...formik.values.category, _id];
    formik.setFieldValue("category", filtered);
  }
  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر محصول</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            id="vehicle"
            name="vehicle"
            onChange={handleChange}
            className={` w-full form-brand h-[48px]`}
          >
            <option>{loading && <Loading />}</option>
            {vehicles?.map((e) => {
              return <option value={e._id}>{e.name}</option>;
            })}
          </select>
          <p className="on-border font-bold">انتخاب محصول*</p>
        </span>
        {selectedVehicle && (
          <>
            <span className="relative">
              <input
                placeholder="مثلا : دستبند النگویی"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.name && formik.touched.name && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.name}
                </small>
              )}
              <p className="on-border font-bold">نام محصول*</p>
            </span>

            <span className="relative">
              <select
                id="category"
                name="category"
                onChange={(e) => handleAddCategory(e.target.value)}
                className={` w-full form-brand h-[48px]`}
              >
                <option></option>
                {categorys?.map((e) => (
                  <option value={e._id}>{e.name}</option>
                ))}
              </select>
              {formik.errors.category && formik.touched.category && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.category}
                </small>
              )}
              <p className="on-border font-bold">دسته بندی*</p>
              <span>دسته بندی های موجود:</span>
              {categorys?.map((cat) =>
                formik.values.category.map((item) => {
                  if (item == cat._id) {
                    return (
                      <div className="flex justify-between items-center my-0.5">
                        <span>{cat.name}</span>
                        <span>
                          <FaTrash
                            onClick={() => handleDeleteCategory(item)}
                            className="cursor-pointer"
                            color="red"
                          />
                        </span>
                      </div>
                    );
                  }
                })
              )}
            </span>
            <span className="relative">
              <input
                placeholder="مثلا : 1.350"
                id="weight"
                name="weight"
                value={formik.values.weight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.weight && formik.touched.weight && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.weight}
                </small>
              )}
              <p className="absolute translate-y-[-50%] top-[24px] left-4">
                gr
              </p>
              <p className="on-border font-bold">وزن*</p>
            </span>

            <span className="relative">
              <input
                placeholder="مثلا : 10"
                id="balance"
                name="balance"
                value={formik.values.balance}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.balance && formik.touched.balance && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.balance}
                </small>
              )}
              <p className="on-border font-bold">تعداد*</p>
            </span>

            <label
              htmlFor="img"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewImg ? (
                <img
                  src={previewImg}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus className="center" size={30} />
              )}

              <p className="on-border font-bold">تصویر اصلی</p>
            </label>
            <input
              onChange={(e) => {
                handleImg(e);
              }}
              type="file"
              className="hidden"
              id="img"
              accept="image/png, image/jpg, image/jpeg"
            />

            <span dir="ltr" className="col-span-full">
              <h1 className="title text-right">:توضیح</h1>
              <ReactQuill
                value={formik.values.desc}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false], font: ["Opens Sans"] }, {}],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image", "code"],
                    ["clean"],
                  ],
                }}
                formats={formats}
                onChange={(value) => {
                  formik.setFieldValue("desc", value);
                }}
              />
            </span>

            <label className="relative col-span-full w-full border-dashed border rounded-[10px] p-3 min-h-[340px]  border-[#444]">
              {previews?.length > 0 ? (
                <div
                  className="p-3"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                  }}
                >
                  {previews &&
                    previews.map((src, i) => {
                      return (
                        <span className="relative">
                          <img
                            src={src}
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                            }}
                            alt=""
                          />
                          <FaTrash
                            color="red"
                            className="top-0 right-0 absolute"
                            onClick={() => deleteImg(i)}
                          />
                        </span>
                      );
                    })}
                  <label htmlFor="images" className="cursor-pointer">
                    <FaPlus size={30} />
                  </label>
                </div>
              ) : (
                <label htmlFor="images" className="center">
                  <FaPlus htmlFor="images" size={30} />
                </label>
              )}

              <p className="on-border font-bold">تصاویر</p>
            </label>
            <input
              onChange={(e) => {
                handleImages(e);
              }}
              type="file"
              className="hidden"
              id="images"
              accept="image/png, image/jpg, image/jpeg"
            />
            <span className="col-span-full flex gap-4">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <button className="btn-brand">تغییر محصول</button>
                  <button
                    type="button"
                    onClick={handleDelete}
                    className=" btn-brand-outline"
                  >
                    {" "}
                    حذف محصول
                  </button>
                </>
              )}
            </span>
          </>
        )}
      </form>
    </div>
  );
};

export default EditProductForm;
