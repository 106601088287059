import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Loading from "../Loading";
import { FaTrash } from "react-icons/fa6";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";

const CreateProductForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [categorys, setCategorys] = useState();
  const [loading, setloading] = useState();
  const [basic_details, setbasic_details] = useState([{ name: "", value: "" }]);
  const [details_info, setdetails_info] = useState([{ name: "", value: "" }]);
  const [previewCatalog, setpreviewCatalog] = useState();
  const [catalog, setCatalog] = useState();
  const [previewVideo, setPreviewVideo] = useState();
  const [video, setVideo] = useState();
  function handleAddBasicDetails() {
    formik.values.basic_details.push({ name: "", value: "" });
    setbasic_details([...basic_details, { name: "", value: "" }]);
  }
  function handleDeleteBasicDetails() {
    const newArr = basic_details.slice(0, basic_details.length - 1);
    setbasic_details(newArr);
  }

  function handleAddDetailsInfo() {
    formik.values.details_info.push({ name: "", value: "" });
    setdetails_info([...details_info, { name: "", value: "" }]);
  }
  function handleDeleteDetailsInfo() {
    const newArr = details_info.slice(0, details_info.length - 1);
    setdetails_info(newArr);
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      makingFee: Yup.string().required("الزامی است"),
      weight: Yup.string().required("الزامی است"),
    });
  };

  async function deleteImg(index) {
    setPreview((students) => students.filter((s, i) => i != index));
    setImages((students) => students.filter((s, i) => i != index));
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      desc: "",
      category: [],
      weight: "",
      balance: "",
      makingFee: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      if (!img) {
        setloading(false);
        return toast.error("کاور نمی تواند خالی باشد");
      }
      formdata.append("name", values.name);
      formdata.append("desc", values.desc);
      formdata.append("category", JSON.stringify(values.category));
      formdata.append("weight", values.weight);
      formdata.append("balance", values.balance);
      formdata.append("makingFee", values.makingFee);
      formdata.append("img", img, img?.name);
      if (images) {
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index], images[index]?.name);
        }
      }

      await fetch(BASE_URL + "/product", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then(async (dat) => {
        const res = await dat.json();
        if (dat?.status == 201) {
          toast.success("محصول ساخته شد");
          setloading(false);
          formik.resetForm();
          setImg(null);
          setImages([]);
          setPreviewImg(null);
          setPreview([]);
          setCatalog(null);
          setVideo(null);
        } else {
          setloading(false);
          toast.error(res.message);
        }
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  function handleImages(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils?.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImages(fils[index]);
        }
      }
    }
  }

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    await httpReauest("GET", "/category", {}, {}).then(({ data }) => {
      setCategorys(data?.data);
    });
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  function handleChange(value) {
    formik.setFieldValue("desc", value);
  }

  function handleDeleteCategory(_id) {
    const filtered = formik.values.category.filter((item) => item !== _id);
    formik.setFieldValue("category", filtered);
  }

  function handleAddCategory(_id) {
    if (formik.values.category.includes(_id) || _id == "" || !_id) {
      return;
    }
    const filtered = [...formik.values.category, _id];
    formik.setFieldValue("category", filtered);
  }

 

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم ایجاد محصول</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <input
            placeholder="مثلا : دستبند النگویی"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.name && formik.touched.name && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.name}
            </small>
          )}
          <p className="on-border font-bold">نام محصول*</p>
        </span>

        <span className="relative">
          <select
            id="category"
            name="category"
            onChange={(e) => handleAddCategory(e.target.value)}
            className={` w-full form-brand h-[48px]`}
          >
            <option></option>
            {categorys?.map((e) => (
              <option value={e._id}>{e.name}</option>
            ))}
          </select>
          {formik.errors.category && formik.touched.category && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.category}
            </small>
          )}
          <p className="on-border font-bold">دسته بندی*</p>
          <span>دسته بندی های موجود:</span>
          {categorys?.map((cat) =>
            formik.values.category.map((item) => {
              if (item == cat._id) {
                return (
                  <div className="flex justify-between items-center my-0.5">
                    <span>{cat.name}</span>
                    <span>
                      <FaTrash
                        onClick={() => handleDeleteCategory(item)}
                        className="cursor-pointer"
                        color="red"
                      />
                    </span>
                  </div>
                );
              }
            })
          )}
        </span>
        <span className="relative">
          <input
            placeholder="مثلا : 1.350"
            id="weight"
            name="weight"
            value={formik.values.weight}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.weight && formik.touched.weight && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.weight}
            </small>
          )}
          <p className="absolute translate-y-[-50%] top-[24px] left-4">gr</p>
          <p className="on-border font-bold">وزن*</p>
        </span>
        <span className="relative">
          <input
            placeholder="مثلا : 1.350"
            id="makingFee"
            name="makingFee"
            value={formik.values.makingFee}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.makingFee && formik.touched.makingFee && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.makingFee}
            </small>
          )}
          <p className="on-border font-bold">اجرت*</p>
        </span>
        <span className="relative">
          <input
            placeholder="مثلا : 10"
            id="balance"
            name="balance"
            value={formik.values.balance}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.balance && formik.touched.balance && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.balance}
            </small>
          )}
          <p className="on-border font-bold">تعداد*</p>
        </span>

        <label
          htmlFor="img"
          className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
        >
          {previewImg ? (
            <img
              src={previewImg}
              className="w-full h-full object-contain"
              alt=""
            />
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">تصویر اصلی</p>
        </label>
        <input
          onChange={(e) => {
            handleImg(e);
          }}
          type="file"
          className="hidden"
          id="img"
          accept="image/png, image/jpg, image/jpeg"
        />

        <span dir="ltr" className="col-span-full">
          <h1 className="title text-right">:توضیح</h1>
          <ReactQuill
            value={formik.values.desc}
            modules={{
              toolbar: [
                [{ header: [1, 2, false], font: ["Opens Sans"] }, {}],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link", "image", "code"],
                ["clean"],
              ],
            }}
            formats={formats}
            onChange={handleChange}
          />
        </span>

        <div className="relative col-span-full w-full border-dashed border rounded-[10px] p-3 min-h-[340px]  border-[#444]">
          {previews?.length > 0 ? (
            <div
              className="p-3"
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              {previews &&
                previews.map((src, i) => {
                  return (
                    <span className="relative">
                      <img
                        src={src}
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <FaTrash
                        color="red"
                        className="top-0 right-0 absolute"
                        onClick={() => deleteImg(i)}
                      />
                    </span>
                  );
                })}
              <label htmlFor="images" className="cursor-pointer">
                <FaPlus size={50} />
              </label>
            </div>
          ) : (
            <label htmlFor="images" className="cursor-pointer">
              <FaPlus className="center" size={50} />
            </label>
          )}

          <p className="on-border font-bold">تصاویر</p>
        </div>
        <input
          onChange={(e) => {
            handleImages(e);
          }}
          type="file"
          className="hidden"
          id="images"
          accept="image/png, image/jpg, image/jpeg"
        />
        {loading ? (
          <Loading />
        ) : (
          <button type="submit" className="btn-brand">
            ارسال
          </button>
        )}
      </form>
    </div>
  );
};

export default CreateProductForm;
