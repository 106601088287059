import React, { useEffect, useState } from "react";
import Loading from "../components/common/Loading";
import { toast } from "react-toastify";
import { httpReauest } from "../utils/httpRequest";

const LoginPage = () => {
  const [name, setName] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState();
  const [code, setcode] = useState();
  const [step, setstep] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!name && !password) {
      return toast.error("فیلد خالی وجود دارد");
    }
    setLoading(true);
    await httpReauest("POST", "/admin/login", { email: name, password })
      .then(async (res) => {
        if (res.status == 201) {
          toast.success("موفقیت امیز");
          await localStorage.setItem("user", JSON.stringify(res.data.data));
          window.location.href = window.origin + "/dashboard";
        }
      })
      .catch((data) => {
        setLoading(false);
        toast.error(data?.response?.data?.message);
      });
  }

  async function handleAcceptCode(e) {
    e.preventDefault();
    setLoading(true);

    await httpReauest("POST", "/admin/login/accept", { email: name, code })
      .then(async (res) => {
        if (res.status == 201) {
          toast.success("موفقیت امیز");
          await localStorage.setItem("user", JSON.stringify(res.data.data));
          window.location.href = window.origin + "/dashboard";
        }
      })
      .catch((data) => {
        setLoading(false);
        toast.error(data?.response?.data?.message);
      });
  }

  return (
    <div
      dir="rtl"
      className="w-[100vw] h-[100vh] flex justify-center items-center"
    >
      <div className="border-brand px-10 py-10">
        <h1 className="title mb-8">Dr Gold پنل</h1>

        <form onSubmit={handleSubmit} className="flex flex-col gap-12">
          <span className="relative w-full">
            <input
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full form-brand min-w-[300px] h-[58px]"
            />
            <p className="on-border font-bold">نام کاربری</p>
          </span>
          <span className="relative w-full">
            <input
              value={password}
              id="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              className="w-full form-brand min-w-[300px] h-[58px]"
              type="password"
            />
            <p className="on-border font-bold">رمز عبور</p>
          </span>
          <span>
            {loading ? (
              <Loading />
            ) : (
              <button type="submit" className="btn-brand w-full">
                ورود
              </button>
            )}
          </span>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
