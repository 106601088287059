import React, { useEffect, useState } from "react";
import { httpReauest } from "../../../utils/httpRequest";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import Loading from "../Loading";
import ReactQuill from "react-quill";

const EditCourseForm = () => {
  const [brands, setBrands] = useState();
  const [img, setImg] = useState();
  const [img2, setImg2] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [previewImg2, setPreviewImg2] = useState();
  const [loading, setloading] = useState();
  const [selectedBrand, setselectedBrand] = useState();
  const [video, setvideo] = useState();
  const [previewVideo, setPreviewVideo] = useState();

  useEffect(() => {
    stayLogin();
  }, []);

  async function stayLogin() {
    await httpReauest("GET", "/course", {}, {}).then(({ data }) => {
      setBrands(data?.data);
    });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      desc: Yup.string().required("الزامی است"),
      price: Yup.string().required("الزامی است"),
      price_person: Yup.string().required("الزامی است"),
      more_desc: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      desc: "",
      special_price: "",
      price: "",
      price_person: "",
      special_price_person: "",
      more_desc: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      if (img) {
        formdata.append("img", img, img?.name);
      }
      if (video) {
        formdata.append("video", video, video?.name);
      }

      const newPrice =
        typeof formik.values.price === "string"
          ? Number(values.price?.replaceAll(",", ""))
          : values.price;
      const newSpecial_price =
        typeof formik.values.special_price === "string"
          ? Number(values.special_price?.replaceAll(",", ""))
          : values.special_price;
      const newSpecial_price_person =
        typeof formik.values.special_price_person === "string"
          ? Number(values.special_price_person?.replaceAll(",", ""))
          : values.special_price_person;
      const newPrice_person =
        typeof formik.values.price_person === "string"
          ? Number(values.price_person?.replaceAll(",", ""))
          : values.price_person;

      formdata.append("price", newPrice);
      formdata.append("special_price", newSpecial_price);
      formdata.append("special_price_person", newSpecial_price_person);
      formdata.append("price_person", newPrice_person);
      formdata.append("name", values.name);
      formdata.append("desc", values.desc);
      formdata.append("more_desc", values.more_desc);

      await fetch(BASE_URL + "/course/" + selectedBrand?._id, {
        method: "PUT",
        body: formdata,
        headers: { "x-access-token": user.token },
      })
        .then((dat) => {
          if (dat?.status == 201) {
            toast.success("دوره تغییر یافت");
            setloading(false);
            formik.resetForm();
            setImg(null);
            setvideo(null);
            setPreviewImg(null);
            setPreviewVideo(null);
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error(err.response?.data?.message);
        });
    },
  });

  async function handleDelete(params) {
    const user = JSON.parse(localStorage.getItem("user"));
    await fetch(BASE_URL + "/course/" + selectedBrand?._id, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("دوره حذف شد");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  async function handleChangeData(e) {
    const newBrand = await brands.find((value) => value._id == e.target.value);

    setselectedBrand(newBrand);
    formik.setFieldValue("name", newBrand.name);
    formik.setFieldValue("price", newBrand?.price);
    formik.setFieldValue("special_price", newBrand?.special_price);
    formik.setFieldValue(
      "special_price_person",
      newBrand?.special_price_person
    );
    formik.setFieldValue("price_person", newBrand?.price_person);
    formik.setFieldValue("desc", newBrand.desc);
    formik.setFieldValue("more_desc", newBrand.more_desc);

    setPreviewImg(CDN_BASE_URL + newBrand?.img);
    setPreviewVideo(CDN_BASE_URL + newBrand?.video);
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  function handleChange(value) {
    formik.setFieldValue("more_desc", value);
  }

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }
  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  function handleVideo(e) {
    const fils = e.target.files[0];
    if (fils.size > 50031517) {
      toast.error("حجم فایل باید کمتر از 50 مگابایت باشد");
    } else {
      setvideo(fils);
      setPreviwVideo(fils);
    }
  }

  async function setPreviwVideo(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewVideo(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  function handleChangePrice(filed, value) {
    const formattedValue = (
      Number(value.replace(/\D/g, "")) || ""
    ).toLocaleString();
    formik.setFieldValue(filed, formattedValue);
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر دوره</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            id="brand"
            name="brand"
            onChange={handleChangeData}
            className={` w-full form-brand h-[48px]`}
          >
            <option></option>
            {brands?.map((e) => {
              if (!e.end) {
                return <option value={e._id}>{e.name}</option>;
              }
            })}
          </select>
          <p className="on-border font-bold">انتخاب دوره*</p>
        </span>
        {selectedBrand && (
          <>
            <span className="relative">
              <input
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.name && formik.touched.name && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.name}
                </small>
              )}
              <p className="on-border font-bold">نام دوره*</p>
            </span>
            <span className="relative">
              <input
                id="price"
                name="price"
                value={formik.values.price}
                onChange={(e) => handleChangePrice("price", e.target.value)}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.price && formik.touched.price && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.price}
                </small>
              )}
              <p className="on-border font-bold">قیمت آنلاین*</p>{" "}
              <p className="absolute translate-y-[-50%] top-[24px] left-4">
                تومان
              </p>
            </span>
            <span className="relative">
              <input
                id="price_person"
                name="price_person"
                value={formik.values.price_person}
                onChange={(e) =>
                  handleChangePrice("price_person", e.target.value)
                }
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.price_person && formik.touched.price_person && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.price_person}
                </small>
              )}
              <p className="on-border font-bold">قیمت حضوری*</p>{" "}
              <p className="absolute translate-y-[-50%] top-[24px] left-4">
                تومان
              </p>
            </span>
            <span className="relative">
              <input
                id="special_price"
                name="special_price"
                value={formik.values.special_price}
                onChange={(e) =>
                  handleChangePrice("special_price", e.target.value)
                }
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.special_price && formik.touched.special_price && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.special_price}
                </small>
              )}
              <p className="on-border font-bold">قیمت آنلاین با تخفیف</p>{" "}
              <p className="absolute translate-y-[-50%] top-[24px] left-4">
                تومان
              </p>
            </span>
            <span className="relative">
              <input
                id="special_price_person"
                name="special_price_person"
                value={formik.values.special_price_person}
                onChange={(e) =>
                  handleChangePrice("special_price_person", e.target.value)
                }
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.special_price_person &&
                formik.touched.special_price_person && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {formik.errors.special_price_person}
                  </small>
                )}
              <p className="on-border font-bold">قیمت حضوری با تخفیف</p>{" "}
              <p className="absolute translate-y-[-50%] top-[24px] left-4">
                تومان
              </p>
            </span>
            <label
              htmlFor="img"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewImg ? (
                <img
                  src={previewImg}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus className="center" size={30} />
              )}
              <p className="on-border font-bold">کاور*</p>
            </label>
            <input
              onChange={(e) => {
                handleImg(e);
              }}
              type="file"
              className="hidden"
              id="img"
              accept="image/png, image/jpg, image/jpeg"
            />
            <label
              htmlFor="video"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewVideo ? (
                <video
                  src={previewVideo}
                  className="w-full h-full object-contain"
                  alt=""
                  controls
                />
              ) : (
                <FaPlus className="center" size={30} />
              )}
              <p className="on-border font-bold">ویدئو*</p>
            </label>
            <input
              onChange={(e) => {
                handleVideo(e);
              }}
              type="file"
              className="hidden"
              id="video"
              accept="video/*"
            />{" "}
            <span className="relative lg:col-span-2">
              <textarea
                id="desc"
                name="desc"
                value={formik.values.desc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[148px]"
              />
              {formik.errors.desc && formik.touched.desc && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.desc}
                </small>
              )}
              <p className="on-border font-bold">توضیحات کوتاه*</p>
            </span>
            <span dir="ltr" className="relative col-span-full">
              <p className="font-bold text-right mb-2">توضیحات کلی*</p>
              <ReactQuill
                value={formik.values.more_desc}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false], font: ["Opens Sans"] }, {}],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image", "code"],
                    ["clean"],
                  ],
                }}
                formats={formats}
                onChange={handleChange}
              />
            </span>
            <span className="col-span-full flex gap-4">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <button className="btn-brand">تغییر دوره</button>
                  <button
                    type="button"
                    onClick={handleDelete}
                    className=" btn-brand-outline"
                  >
                    {" "}
                    حذف دوره
                  </button>
                </>
              )}
            </span>
          </>
        )}
      </form>
    </div>
  );
};

export default EditCourseForm;
