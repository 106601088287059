import React, { useState } from "react";
import { CDN_BASE_URL } from "../../../config";
import { httpReauest } from "../../../utils/httpRequest";
import { FaCheck, FaTimes } from "react-icons/fa";
import Loading from "../Loading";
import { toast } from "react-toastify";

const UserCourseCard = ({ item }) => {
  const [sended, setSended] = useState(item.sended ? item.sended : false);
  const [loading, setloading] = useState(false);

  async function handleSended() {
    setloading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "POST",
      "/course/sended",
      { Id: item._id, courseId: item?.course?._id },
      { "x-access-token": user.token }
    )
      .then((res) => {
        if (res.status === 201) {
          toast.success("موفقیت آمیز");
          setSended(true);
          setloading(false);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
        setloading(false);
      });
  }
  return (
    <div className="border rounded-[8px] shadow-md p-2">
      <span className="p-2 flex justify-between flex-wrap gap-3">
        <span>نام و نام خانوادگی :</span>
        <span dir="ltr">{item?.name}</span>
      </span>
      <span className="p-2 flex justify-between flex-wrap gap-3">
        <span>شماره تماس:</span>
        <span dir="ltr">{item.phone}</span>
      </span>
      <span className="p-2 flex justify-between flex-wrap gap-3">
        <span>دوره:</span>
        <span dir="ltr">{item?.course?.name}</span>
      </span>
      <span className="p-2 flex justify-between flex-wrap gap-3">
        <span>ارسال شده؟:</span>
        <span dir="ltr">
          {sended ? (
            <FaCheck size={30} color="green" />
          ) : (
            <FaTimes size={30} color="red" />
          )}
        </span>
      </span>
      <span className="p-2 flex">
        <span>تصویر چک:(برای مشاهده دقیق رویه عکس کلیک کنید)</span>
        <a href={CDN_BASE_URL + item.payment} target="_blank" rel="noreferrer">
          <img className="w-20" src={CDN_BASE_URL + item.payment} alt="" />
        </a>
      </span>{" "}
      {!sended && (
        <span className="flex justify-center">
          {loading ? (
            <Loading />
          ) : (
            <button onClick={handleSended} className="btn-brand">
              تبدیل به ارسال شده
            </button>
          )}
        </span>
      )}
    </div>
  );
};

export default UserCourseCard;
